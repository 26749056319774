import { memo } from "react";
import PropTypes from "prop-types";
import "./ProductNameWithCategory.scss";

const ProductNameWithCategory = ({ name, category }) => {
	return (
		<span className="product-name-with-category">
			{name}{" "}
			{category > 0 && (
				<>
					{category}
					<sup>*</sup>
				</>
			)}
		</span>
	);
};

ProductNameWithCategory.propTypes = {
	name: PropTypes.string,
	category: PropTypes.number,
};

export default memo(ProductNameWithCategory);
